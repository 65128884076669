@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?b65b7a');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?b65b7a#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?b65b7a') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?b65b7a') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?b65b7a##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="hearthstone-icon-"], [class*="hearthstone-icon-"], .hearthstone-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hearthstone-icon-leaf {
  &:before {
    content: $hearthstone-icon-leaf;
    color: theme-color('leaf-green');
  }
}
.hearthstone-icon-fire, .icon-fire {
  &:before {
    content: $hearthstone-icon-fire;
    color: theme-color('red');
  }
}
.hearthstone-icon-activity {
  &:before {
    content: $hearthstone-icon-activity; 
  }
}
.hearthstone-icon-airplay {
  &:before {
    content: $hearthstone-icon-airplay; 
  }
}
.hearthstone-icon-alert-circle {
  &:before {
    content: $hearthstone-icon-alert-circle; 
  }
}
.hearthstone-icon-alert-octagon {
  &:before {
    content: $hearthstone-icon-alert-octagon; 
  }
}
.hearthstone-icon-alert-triangle {
  &:before {
    content: $hearthstone-icon-alert-triangle; 
  }
}
.hearthstone-icon-align-center {
  &:before {
    content: $hearthstone-icon-align-center; 
  }
}
.hearthstone-icon-align-justify {
  &:before {
    content: $hearthstone-icon-align-justify; 
  }
}
.hearthstone-icon-align-left {
  &:before {
    content: $hearthstone-icon-align-left; 
  }
}
.hearthstone-icon-align-right {
  &:before {
    content: $hearthstone-icon-align-right; 
  }
}
.hearthstone-icon-anchor {
  &:before {
    content: $hearthstone-icon-anchor; 
  }
}
.hearthstone-icon-aperture {
  &:before {
    content: $hearthstone-icon-aperture; 
  }
}
.hearthstone-icon-archive {
  &:before {
    content: $hearthstone-icon-archive; 
  }
}
.hearthstone-icon-arrow-down {
  &:before {
    content: $hearthstone-icon-arrow-down; 
  }
}
.hearthstone-icon-arrow-down-circle {
  &:before {
    content: $hearthstone-icon-arrow-down-circle; 
  }
}
.hearthstone-icon-arrow-down-left {
  &:before {
    content: $hearthstone-icon-arrow-down-left; 
  }
}
.hearthstone-icon-arrow-down-right {
  &:before {
    content: $hearthstone-icon-arrow-down-right; 
  }
}
.hearthstone-icon-arrow-left {
  &:before {
    content: $hearthstone-icon-arrow-left; 
  }
}
.hearthstone-icon-arrow-left-circle {
  &:before {
    content: $hearthstone-icon-arrow-left-circle; 
  }
}
.hearthstone-icon-arrow-right {
  &:before {
    content: $hearthstone-icon-arrow-right; 
  }
}
.hearthstone-icon-arrow-right-circle {
  &:before {
    content: $hearthstone-icon-arrow-right-circle; 
  }
}
.hearthstone-icon-arrow-up {
  &:before {
    content: $hearthstone-icon-arrow-up; 
  }
}
.hearthstone-icon-arrow-up-circle {
  &:before {
    content: $hearthstone-icon-arrow-up-circle; 
  }
}
.hearthstone-icon-arrow-up-left {
  &:before {
    content: $hearthstone-icon-arrow-up-left; 
  }
}
.hearthstone-icon-arrow-up-right {
  &:before {
    content: $hearthstone-icon-arrow-up-right; 
  }
}
.hearthstone-icon-at-sign {
  &:before {
    content: $hearthstone-icon-at-sign; 
  }
}
.hearthstone-icon-award {
  &:before {
    content: $hearthstone-icon-award; 
  }
}
.hearthstone-icon-bar-chart {
  &:before {
    content: $hearthstone-icon-bar-chart; 
  }
}
.hearthstone-icon-bar-chart-2 {
  &:before {
    content: $hearthstone-icon-bar-chart-2; 
  }
}
.hearthstone-icon-battery {
  &:before {
    content: $hearthstone-icon-battery; 
  }
}
.hearthstone-icon-battery-charging {
  &:before {
    content: $hearthstone-icon-battery-charging; 
  }
}
.hearthstone-icon-bell {
  &:before {
    content: $hearthstone-icon-bell; 
  }
}
.hearthstone-icon-bell-off {
  &:before {
    content: $hearthstone-icon-bell-off; 
  }
}
.hearthstone-icon-bluetooth {
  &:before {
    content: $hearthstone-icon-bluetooth; 
  }
}
.hearthstone-icon-bold {
  &:before {
    content: $hearthstone-icon-bold; 
  }
}
.hearthstone-icon-book {
  &:before {
    content: $hearthstone-icon-book; 
  }
}
.hearthstone-icon-book-open {
  &:before {
    content: $hearthstone-icon-book-open; 
  }
}
.hearthstone-icon-bookmark {
  &:before {
    content: $hearthstone-icon-bookmark; 
  }
}
.hearthstone-icon-box {
  &:before {
    content: $hearthstone-icon-box; 
  }
}
.hearthstone-icon-briefcase {
  &:before {
    content: $hearthstone-icon-briefcase; 
  }
}
.hearthstone-icon-calendar {
  &:before {
    content: $hearthstone-icon-calendar; 
  }
}
.hearthstone-icon-camera {
  &:before {
    content: $hearthstone-icon-camera; 
  }
}
.hearthstone-icon-camera-off {
  &:before {
    content: $hearthstone-icon-camera-off; 
  }
}
.hearthstone-icon-cast {
  &:before {
    content: $hearthstone-icon-cast; 
  }
}
.hearthstone-icon-check {
  &:before {
    content: $hearthstone-icon-check; 
  }
}
.hearthstone-icon-check-circle {
  &:before {
    content: $hearthstone-icon-check-circle; 
  }
}
.hearthstone-icon-check-square {
  &:before {
    content: $hearthstone-icon-check-square; 
  }
}
.hearthstone-icon-chevron-down {
  &:before {
    content: $hearthstone-icon-chevron-down; 
  }
}
.hearthstone-icon-chevron-left {
  &:before {
    content: $hearthstone-icon-chevron-left; 
  }
}
.hearthstone-icon-chevron-right {
  &:before {
    content: $hearthstone-icon-chevron-right; 
  }
}
.hearthstone-icon-chevron-up {
  &:before {
    content: $hearthstone-icon-chevron-up; 
  }
}
.hearthstone-icon-chevrons-down {
  &:before {
    content: $hearthstone-icon-chevrons-down; 
  }
}
.hearthstone-icon-chevrons-left {
  &:before {
    content: $hearthstone-icon-chevrons-left; 
  }
}
.hearthstone-icon-chevrons-right {
  &:before {
    content: $hearthstone-icon-chevrons-right; 
  }
}
.hearthstone-icon-chevrons-up {
  &:before {
    content: $hearthstone-icon-chevrons-up; 
  }
}
.hearthstone-icon-chrome {
  &:before {
    content: $hearthstone-icon-chrome; 
  }
}
.hearthstone-icon-circle {
  &:before {
    content: $hearthstone-icon-circle; 
  }
}
.hearthstone-icon-clipboard {
  &:before {
    content: $hearthstone-icon-clipboard; 
  }
}
.hearthstone-icon-clock {
  &:before {
    content: $hearthstone-icon-clock; 
  }
}
.hearthstone-icon-cloud {
  &:before {
    content: $hearthstone-icon-cloud; 
  }
}
.hearthstone-icon-cloud-drizzle {
  &:before {
    content: $hearthstone-icon-cloud-drizzle; 
  }
}
.hearthstone-icon-cloud-lightning {
  &:before {
    content: $hearthstone-icon-cloud-lightning; 
  }
}
.hearthstone-icon-cloud-off {
  &:before {
    content: $hearthstone-icon-cloud-off; 
  }
}
.hearthstone-icon-cloud-rain {
  &:before {
    content: $hearthstone-icon-cloud-rain; 
  }
}
.hearthstone-icon-cloud-snow {
  &:before {
    content: $hearthstone-icon-cloud-snow; 
  }
}
.hearthstone-icon-code {
  &:before {
    content: $hearthstone-icon-code; 
  }
}
.hearthstone-icon-codepen {
  &:before {
    content: $hearthstone-icon-codepen; 
  }
}
.hearthstone-icon-codesandbox {
  &:before {
    content: $hearthstone-icon-codesandbox; 
  }
}
.hearthstone-icon-coffee {
  &:before {
    content: $hearthstone-icon-coffee; 
  }
}
.hearthstone-icon-columns {
  &:before {
    content: $hearthstone-icon-columns; 
  }
}
.hearthstone-icon-command {
  &:before {
    content: $hearthstone-icon-command; 
  }
}
.hearthstone-icon-compass {
  &:before {
    content: $hearthstone-icon-compass; 
  }
}
.hearthstone-icon-copy {
  &:before {
    content: $hearthstone-icon-copy; 
  }
}
.hearthstone-icon-corner-down-left {
  &:before {
    content: $hearthstone-icon-corner-down-left; 
  }
}
.hearthstone-icon-corner-down-right {
  &:before {
    content: $hearthstone-icon-corner-down-right; 
  }
}
.hearthstone-icon-corner-left-down {
  &:before {
    content: $hearthstone-icon-corner-left-down; 
  }
}
.hearthstone-icon-corner-left-up {
  &:before {
    content: $hearthstone-icon-corner-left-up; 
  }
}
.hearthstone-icon-corner-right-down {
  &:before {
    content: $hearthstone-icon-corner-right-down; 
  }
}
.hearthstone-icon-corner-right-up {
  &:before {
    content: $hearthstone-icon-corner-right-up; 
  }
}
.hearthstone-icon-corner-up-left {
  &:before {
    content: $hearthstone-icon-corner-up-left; 
  }
}
.hearthstone-icon-corner-up-right {
  &:before {
    content: $hearthstone-icon-corner-up-right; 
  }
}
.hearthstone-icon-cpu {
  &:before {
    content: $hearthstone-icon-cpu; 
  }
}
.hearthstone-icon-credit-card {
  &:before {
    content: $hearthstone-icon-credit-card; 
  }
}
.hearthstone-icon-crop {
  &:before {
    content: $hearthstone-icon-crop; 
  }
}
.hearthstone-icon-crosshair {
  &:before {
    content: $hearthstone-icon-crosshair; 
  }
}
.hearthstone-icon-database {
  &:before {
    content: $hearthstone-icon-database; 
  }
}
.hearthstone-icon-delete {
  &:before {
    content: $hearthstone-icon-delete; 
  }
}
.hearthstone-icon-disc {
  &:before {
    content: $hearthstone-icon-disc; 
  }
}
.hearthstone-icon-dollar-sign, .icon-dollar-sign {
  &:before {
    content: $hearthstone-icon-dollar-sign; 
  }
}
.hearthstone-icon-download {
  &:before {
    content: $hearthstone-icon-download; 
  }
}
.hearthstone-icon-download-cloud {
  &:before {
    content: $hearthstone-icon-download-cloud; 
  }
}
.hearthstone-icon-droplet {
  &:before {
    content: $hearthstone-icon-droplet; 
  }
}
.hearthstone-icon-edit {
  &:before {
    content: $hearthstone-icon-edit; 
  }
}
.hearthstone-icon-edit-2 {
  &:before {
    content: $hearthstone-icon-edit-2; 
  }
}
.hearthstone-icon-edit-3 {
  &:before {
    content: $hearthstone-icon-edit-3; 
  }
}
.hearthstone-icon-external-link {
  &:before {
    content: $hearthstone-icon-external-link; 
  }
}
.hearthstone-icon-eye {
  &:before {
    content: $hearthstone-icon-eye; 
  }
}
.hearthstone-icon-eye-off {
  &:before {
    content: $hearthstone-icon-eye-off; 
  }
}
.hearthstone-icon-facebook {
  &:before {
    content: $hearthstone-icon-facebook; 
  }
}
.hearthstone-icon-fast-forward {
  &:before {
    content: $hearthstone-icon-fast-forward; 
  }
}
.hearthstone-icon-feather {
  &:before {
    content: $hearthstone-icon-feather; 
  }
}
.hearthstone-icon-figma {
  &:before {
    content: $hearthstone-icon-figma; 
  }
}
.hearthstone-icon-file {
  &:before {
    content: $hearthstone-icon-file; 
  }
}
.hearthstone-icon-file-minus {
  &:before {
    content: $hearthstone-icon-file-minus; 
  }
}
.hearthstone-icon-file-plus {
  &:before {
    content: $hearthstone-icon-file-plus; 
  }
}
.hearthstone-icon-file-text {
  &:before {
    content: $hearthstone-icon-file-text; 
  }
}
.hearthstone-icon-film {
  &:before {
    content: $hearthstone-icon-film; 
  }
}
.hearthstone-icon-filter {
  &:before {
    content: $hearthstone-icon-filter; 
  }
}
.hearthstone-icon-flag {
  &:before {
    content: $hearthstone-icon-flag; 
  }
}
.hearthstone-icon-folder {
  &:before {
    content: $hearthstone-icon-folder; 
  }
}
.hearthstone-icon-folder-minus {
  &:before {
    content: $hearthstone-icon-folder-minus; 
  }
}
.hearthstone-icon-folder-plus {
  &:before {
    content: $hearthstone-icon-folder-plus; 
  }
}
.hearthstone-icon-framer {
  &:before {
    content: $hearthstone-icon-framer; 
  }
}
.hearthstone-icon-frown {
  &:before {
    content: $hearthstone-icon-frown; 
  }
}
.hearthstone-icon-gift {
  &:before {
    content: $hearthstone-icon-gift; 
  }
}
.hearthstone-icon-git-branch {
  &:before {
    content: $hearthstone-icon-git-branch; 
  }
}
.hearthstone-icon-git-commit {
  &:before {
    content: $hearthstone-icon-git-commit; 
  }
}
.hearthstone-icon-git-merge {
  &:before {
    content: $hearthstone-icon-git-merge; 
  }
}
.hearthstone-icon-git-pull-request {
  &:before {
    content: $hearthstone-icon-git-pull-request; 
  }
}
.hearthstone-icon-github {
  &:before {
    content: $hearthstone-icon-github; 
  }
}
.hearthstone-icon-gitlab {
  &:before {
    content: $hearthstone-icon-gitlab; 
  }
}
.hearthstone-icon-globe {
  &:before {
    content: $hearthstone-icon-globe; 
  }
}
.hearthstone-icon-grid {
  &:before {
    content: $hearthstone-icon-grid; 
  }
}
.hearthstone-icon-hard-drive {
  &:before {
    content: $hearthstone-icon-hard-drive; 
  }
}
.hearthstone-icon-hash {
  &:before {
    content: $hearthstone-icon-hash; 
  }
}
.hearthstone-icon-headphones {
  &:before {
    content: $hearthstone-icon-headphones; 
  }
}
.hearthstone-icon-heart {
  &:before {
    content: $hearthstone-icon-heart; 
  }
}
.hearthstone-icon-help-circle {
  &:before {
    content: $hearthstone-icon-help-circle; 
  }
}
.hearthstone-icon-hexagon {
  &:before {
    content: $hearthstone-icon-hexagon; 
  }
}
.hearthstone-icon-home {
  &:before {
    content: $hearthstone-icon-home; 
  }
}
.hearthstone-icon-image {
  &:before {
    content: $hearthstone-icon-image; 
  }
}
.hearthstone-icon-inbox {
  &:before {
    content: $hearthstone-icon-inbox; 
  }
}
.hearthstone-icon-info {
  &:before {
    content: $hearthstone-icon-info; 
  }
}
.hearthstone-icon-instagram {
  &:before {
    content: $hearthstone-icon-instagram; 
  }
}
.hearthstone-icon-italic {
  &:before {
    content: $hearthstone-icon-italic; 
  }
}
.hearthstone-icon-key {
  &:before {
    content: $hearthstone-icon-key; 
  }
}
.hearthstone-icon-layers {
  &:before {
    content: $hearthstone-icon-layers; 
  }
}
.hearthstone-icon-layout {
  &:before {
    content: $hearthstone-icon-layout; 
  }
}
.hearthstone-icon-life-buoy {
  &:before {
    content: $hearthstone-icon-life-buoy; 
  }
}
.hearthstone-icon-link {
  &:before {
    content: $hearthstone-icon-link; 
  }
}
.hearthstone-icon-link-2 {
  &:before {
    content: $hearthstone-icon-link-2; 
  }
}
.hearthstone-icon-linkedin {
  &:before {
    content: $hearthstone-icon-linkedin; 
  }
}
.hearthstone-icon-list {
  &:before {
    content: $hearthstone-icon-list; 
  }
}
.hearthstone-icon-loader {
  &:before {
    content: $hearthstone-icon-loader; 
  }
}
.hearthstone-icon-lock {
  &:before {
    content: $hearthstone-icon-lock; 
  }
}
.hearthstone-icon-log-in {
  &:before {
    content: $hearthstone-icon-log-in; 
  }
}
.hearthstone-icon-log-out {
  &:before {
    content: $hearthstone-icon-log-out; 
  }
}
.hearthstone-icon-mail {
  &:before {
    content: $hearthstone-icon-mail; 
  }
}
.hearthstone-icon-map {
  &:before {
    content: $hearthstone-icon-map; 
  }
}
.hearthstone-icon-map-pin {
  &:before {
    content: $hearthstone-icon-map-pin; 
  }
}
.hearthstone-icon-maximize {
  &:before {
    content: $hearthstone-icon-maximize; 
  }
}
.hearthstone-icon-maximize-2 {
  &:before {
    content: $hearthstone-icon-maximize-2; 
  }
}
.hearthstone-icon-meh {
  &:before {
    content: $hearthstone-icon-meh; 
  }
}
.hearthstone-icon-menu {
  &:before {
    content: $hearthstone-icon-menu; 
  }
}
.hearthstone-icon-message-circle {
  &:before {
    content: $hearthstone-icon-message-circle; 
  }
}
.hearthstone-icon-message-square {
  &:before {
    content: $hearthstone-icon-message-square; 
  }
}
.hearthstone-icon-mic {
  &:before {
    content: $hearthstone-icon-mic; 
  }
}
.hearthstone-icon-mic-off {
  &:before {
    content: $hearthstone-icon-mic-off; 
  }
}
.hearthstone-icon-minimize {
  &:before {
    content: $hearthstone-icon-minimize; 
  }
}
.hearthstone-icon-minimize-2 {
  &:before {
    content: $hearthstone-icon-minimize-2; 
  }
}
.hearthstone-icon-minus {
  &:before {
    content: $hearthstone-icon-minus; 
  }
}
.hearthstone-icon-minus-circle {
  &:before {
    content: $hearthstone-icon-minus-circle; 
  }
}
.hearthstone-icon-minus-square {
  &:before {
    content: $hearthstone-icon-minus-square; 
  }
}
.hearthstone-icon-monitor {
  &:before {
    content: $hearthstone-icon-monitor; 
  }
}
.hearthstone-icon-moon {
  &:before {
    content: $hearthstone-icon-moon; 
  }
}
.hearthstone-icon-more-horizontal {
  &:before {
    content: $hearthstone-icon-more-horizontal; 
  }
}
.hearthstone-icon-more-vertical {
  &:before {
    content: $hearthstone-icon-more-vertical; 
  }
}
.hearthstone-icon-mouse-pointer {
  &:before {
    content: $hearthstone-icon-mouse-pointer; 
  }
}
.hearthstone-icon-move {
  &:before {
    content: $hearthstone-icon-move; 
  }
}
.hearthstone-icon-music {
  &:before {
    content: $hearthstone-icon-music; 
  }
}
.hearthstone-icon-navigation {
  &:before {
    content: $hearthstone-icon-navigation; 
  }
}
.hearthstone-icon-navigation-2 {
  &:before {
    content: $hearthstone-icon-navigation-2; 
  }
}
.hearthstone-icon-octagon {
  &:before {
    content: $hearthstone-icon-octagon; 
  }
}
.hearthstone-icon-package {
  &:before {
    content: $hearthstone-icon-package; 
  }
}
.hearthstone-icon-paperclip {
  &:before {
    content: $hearthstone-icon-paperclip; 
  }
}
.hearthstone-icon-pause {
  &:before {
    content: $hearthstone-icon-pause; 
  }
}
.hearthstone-icon-pause-circle {
  &:before {
    content: $hearthstone-icon-pause-circle; 
  }
}
.hearthstone-icon-pen-tool {
  &:before {
    content: $hearthstone-icon-pen-tool; 
  }
}
.hearthstone-icon-percent {
  &:before {
    content: $hearthstone-icon-percent; 
  }
}
.hearthstone-icon-phone {
  &:before {
    content: $hearthstone-icon-phone; 
  }
}
.hearthstone-icon-phone-call {
  &:before {
    content: $hearthstone-icon-phone-call; 
  }
}
.hearthstone-icon-phone-forwarded {
  &:before {
    content: $hearthstone-icon-phone-forwarded; 
  }
}
.hearthstone-icon-phone-incoming {
  &:before {
    content: $hearthstone-icon-phone-incoming; 
  }
}
.hearthstone-icon-phone-missed {
  &:before {
    content: $hearthstone-icon-phone-missed; 
  }
}
.hearthstone-icon-phone-off {
  &:before {
    content: $hearthstone-icon-phone-off; 
  }
}
.hearthstone-icon-phone-outgoing {
  &:before {
    content: $hearthstone-icon-phone-outgoing; 
  }
}
.hearthstone-icon-pie-chart {
  &:before {
    content: $hearthstone-icon-pie-chart; 
  }
}
.hearthstone-icon-play {
  &:before {
    content: $hearthstone-icon-play; 
  }
}
.hearthstone-icon-play-circle {
  &:before {
    content: $hearthstone-icon-play-circle; 
  }
}
.hearthstone-icon-plus {
  &:before {
    content: $hearthstone-icon-plus; 
  }
}
.hearthstone-icon-plus-circle {
  &:before {
    content: $hearthstone-icon-plus-circle; 
  }
}
.hearthstone-icon-plus-square {
  &:before {
    content: $hearthstone-icon-plus-square; 
  }
}
.hearthstone-icon-pocket {
  &:before {
    content: $hearthstone-icon-pocket; 
  }
}
.hearthstone-icon-power {
  &:before {
    content: $hearthstone-icon-power; 
  }
}
.hearthstone-icon-printer {
  &:before {
    content: $hearthstone-icon-printer; 
  }
}
.hearthstone-icon-radio {
  &:before {
    content: $hearthstone-icon-radio; 
  }
}
.hearthstone-icon-refresh-ccw {
  &:before {
    content: $hearthstone-icon-refresh-ccw; 
  }
}
.hearthstone-icon-refresh-cw {
  &:before {
    content: $hearthstone-icon-refresh-cw; 
  }
}
.hearthstone-icon-repeat {
  &:before {
    content: $hearthstone-icon-repeat; 
  }
}
.hearthstone-icon-rewind {
  &:before {
    content: $hearthstone-icon-rewind; 
  }
}
.hearthstone-icon-rotate-ccw {
  &:before {
    content: $hearthstone-icon-rotate-ccw; 
  }
}
.hearthstone-icon-rotate-cw {
  &:before {
    content: $hearthstone-icon-rotate-cw; 
  }
}
.hearthstone-icon-rss {
  &:before {
    content: $hearthstone-icon-rss; 
  }
}
.hearthstone-icon-save {
  &:before {
    content: $hearthstone-icon-save; 
  }
}
.hearthstone-icon-scissors {
  &:before {
    content: $hearthstone-icon-scissors; 
  }
}
.hearthstone-icon-search {
  &:before {
    content: $hearthstone-icon-search; 
  }
}
.hearthstone-icon-send {
  &:before {
    content: $hearthstone-icon-send; 
  }
}
.hearthstone-icon-server {
  &:before {
    content: $hearthstone-icon-server; 
  }
}
.hearthstone-icon-settings {
  &:before {
    content: $hearthstone-icon-settings; 
  }
}
.hearthstone-icon-share {
  &:before {
    content: $hearthstone-icon-share; 
  }
}
.hearthstone-icon-share-2 {
  &:before {
    content: $hearthstone-icon-share-2; 
  }
}
.hearthstone-icon-shield {
  &:before {
    content: $hearthstone-icon-shield; 
  }
}
.hearthstone-icon-shield-off {
  &:before {
    content: $hearthstone-icon-shield-off; 
  }
}
.hearthstone-icon-shopping-bag {
  &:before {
    content: $hearthstone-icon-shopping-bag; 
  }
}
.hearthstone-icon-shopping-cart {
  &:before {
    content: $hearthstone-icon-shopping-cart; 
  }
}
.hearthstone-icon-shuffle {
  &:before {
    content: $hearthstone-icon-shuffle; 
  }
}
.hearthstone-icon-sidebar {
  &:before {
    content: $hearthstone-icon-sidebar; 
  }
}
.hearthstone-icon-skip-back {
  &:before {
    content: $hearthstone-icon-skip-back; 
  }
}
.hearthstone-icon-skip-forward {
  &:before {
    content: $hearthstone-icon-skip-forward; 
  }
}
.hearthstone-icon-slack {
  &:before {
    content: $hearthstone-icon-slack; 
  }
}
.hearthstone-icon-slash {
  &:before {
    content: $hearthstone-icon-slash; 
  }
}
.hearthstone-icon-sliders {
  &:before {
    content: $hearthstone-icon-sliders; 
  }
}
.hearthstone-icon-smartphone {
  &:before {
    content: $hearthstone-icon-smartphone; 
  }
}
.hearthstone-icon-smile {
  &:before {
    content: $hearthstone-icon-smile; 
  }
}
.hearthstone-icon-speaker {
  &:before {
    content: $hearthstone-icon-speaker; 
  }
}
.hearthstone-icon-square {
  &:before {
    content: $hearthstone-icon-square; 
  }
}
.hearthstone-icon-star {
  &:before {
    content: $hearthstone-icon-star; 
  }
}
.hearthstone-icon-stop-circle {
  &:before {
    content: $hearthstone-icon-stop-circle; 
  }
}
.hearthstone-icon-sun {
  &:before {
    content: $hearthstone-icon-sun; 
  }
}
.hearthstone-icon-sunrise {
  &:before {
    content: $hearthstone-icon-sunrise; 
  }
}
.hearthstone-icon-sunset {
  &:before {
    content: $hearthstone-icon-sunset; 
  }
}
.hearthstone-icon-tablet {
  &:before {
    content: $hearthstone-icon-tablet; 
  }
}
.hearthstone-icon-tag {
  &:before {
    content: $hearthstone-icon-tag; 
  }
}
.hearthstone-icon-target {
  &:before {
    content: $hearthstone-icon-target; 
  }
}
.hearthstone-icon-terminal {
  &:before {
    content: $hearthstone-icon-terminal; 
  }
}
.hearthstone-icon-thermometer {
  &:before {
    content: $hearthstone-icon-thermometer; 
  }
}
.hearthstone-icon-thumbs-down {
  &:before {
    content: $hearthstone-icon-thumbs-down; 
  }
}
.hearthstone-icon-thumbs-up {
  &:before {
    content: $hearthstone-icon-thumbs-up; 
  }
}
.hearthstone-icon-toggle-left {
  &:before {
    content: $hearthstone-icon-toggle-left; 
  }
}
.hearthstone-icon-toggle-right {
  &:before {
    content: $hearthstone-icon-toggle-right; 
  }
}
.hearthstone-icon-tool {
  &:before {
    content: $hearthstone-icon-tool; 
  }
}
.hearthstone-icon-trash {
  &:before {
    content: $hearthstone-icon-trash; 
  }
}
.hearthstone-icon-trash-2 {
  &:before {
    content: $hearthstone-icon-trash-2; 
  }
}
.hearthstone-icon-trello {
  &:before {
    content: $hearthstone-icon-trello; 
  }
}
.hearthstone-icon-trending-down {
  &:before {
    content: $hearthstone-icon-trending-down; 
  }
}
.hearthstone-icon-trending-up {
  &:before {
    content: $hearthstone-icon-trending-up; 
  }
}
.hearthstone-icon-triangle {
  &:before {
    content: $hearthstone-icon-triangle; 
  }
}
.hearthstone-icon-truck {
  &:before {
    content: $hearthstone-icon-truck; 
  }
}
.hearthstone-icon-tv {
  &:before {
    content: $hearthstone-icon-tv; 
  }
}
.hearthstone-icon-twitch {
  &:before {
    content: $hearthstone-icon-twitch; 
  }
}
.hearthstone-icon-twitter {
  &:before {
    content: $hearthstone-icon-twitter; 
  }
}
.hearthstone-icon-type {
  &:before {
    content: $hearthstone-icon-type; 
  }
}
.hearthstone-icon-umbrella {
  &:before {
    content: $hearthstone-icon-umbrella; 
  }
}
.hearthstone-icon-underline {
  &:before {
    content: $hearthstone-icon-underline; 
  }
}
.hearthstone-icon-unlock {
  &:before {
    content: $hearthstone-icon-unlock; 
  }
}
.hearthstone-icon-upload {
  &:before {
    content: $hearthstone-icon-upload; 
  }
}
.hearthstone-icon-upload-cloud {
  &:before {
    content: $hearthstone-icon-upload-cloud; 
  }
}
.hearthstone-icon-user {
  &:before {
    content: $hearthstone-icon-user; 
  }
}
.hearthstone-icon-user-check {
  &:before {
    content: $hearthstone-icon-user-check; 
  }
}
.hearthstone-icon-user-minus {
  &:before {
    content: $hearthstone-icon-user-minus; 
  }
}
.hearthstone-icon-user-plus {
  &:before {
    content: $hearthstone-icon-user-plus; 
  }
}
.hearthstone-icon-user-x {
  &:before {
    content: $hearthstone-icon-user-x; 
  }
}
.hearthstone-icon-users {
  &:before {
    content: $hearthstone-icon-users; 
  }
}
.hearthstone-icon-video {
  &:before {
    content: $hearthstone-icon-video; 
  }
}
.hearthstone-icon-video-off {
  &:before {
    content: $hearthstone-icon-video-off; 
  }
}
.hearthstone-icon-voicemail {
  &:before {
    content: $hearthstone-icon-voicemail; 
  }
}
.hearthstone-icon-volume {
  &:before {
    content: $hearthstone-icon-volume; 
  }
}
.hearthstone-icon-volume-1 {
  &:before {
    content: $hearthstone-icon-volume-1; 
  }
}
.hearthstone-icon-volume-2 {
  &:before {
    content: $hearthstone-icon-volume-2; 
  }
}
.hearthstone-icon-volume-x {
  &:before {
    content: $hearthstone-icon-volume-x; 
  }
}
.hearthstone-icon-watch {
  &:before {
    content: $hearthstone-icon-watch; 
  }
}
.hearthstone-icon-wifi {
  &:before {
    content: $hearthstone-icon-wifi; 
  }
}
.hearthstone-icon-wifi-off {
  &:before {
    content: $hearthstone-icon-wifi-off; 
  }
}
.hearthstone-icon-wind {
  &:before {
    content: $hearthstone-icon-wind; 
  }
}
.hearthstone-icon-x {
  &:before {
    content: $hearthstone-icon-x; 
  }
}
.hearthstone-icon-x-circle {
  &:before {
    content: $hearthstone-icon-x-circle; 
  }
}
.hearthstone-icon-x-octagon {
  &:before {
    content: $hearthstone-icon-x-octagon; 
  }
}
.hearthstone-icon-x-square {
  &:before {
    content: $hearthstone-icon-x-square; 
  }
}
.hearthstone-icon-youtube {
  &:before {
    content: $hearthstone-icon-youtube; 
  }
}
.hearthstone-icon-zap {
  &:before {
    content: $hearthstone-icon-zap; 
  }
}
.hearthstone-icon-zap-off {
  &:before {
    content: $hearthstone-icon-zap-off; 
  }
}
.hearthstone-icon-zoom-in {
  &:before {
    content: $hearthstone-icon-zoom-in; 
  }
}
.hearthstone-icon-zoom-out {
  &:before {
    content: $hearthstone-icon-zoom-out; 
  }
}

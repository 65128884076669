.page-template-fullwidthpage {
	.bytesco-block.hero-image-block {
		&.apply-negative-top-margin {
			margin-top: -148px !important;
		}
	}
}
.bytesco-block.hero-image-block{
	top: 0;
	right: 0;
	bottom: 560px;
	left: 0;
	background-size: cover;
	background-position: right center;
	min-height: 560px;
	position: relative;
	.small-image-header & {
		bottom: 310px;
		min-height: 310px;
	}
	@include media-breakpoint-up(md){
		bottom: 710px;
		min-height: 710px;
		.small-image-header & {
			bottom: 410px;
			min-height: 410px;
		}
	}
	&.apply-negative-top-margin{
		margin-top: -120px!important;
	}
	.hero-image-overlay{
		position: absolute;
		left: 0;
		right:0;
		bottom: 0;
		top:0;
		background: linear-gradient(270deg, rgba(81,76,71,0) 0%, #514C47 99.92%);
		padding-top: 90px;
	}

	.inner-blocks{
		@include media-breakpoint-up(lg) {
			padding-top: 118px;
		}
		@include media-breakpoint-down(lg) {
			padding-top: 139px;
		}
		.content-group,
		.button-group{
			padding-left: 21px;
		}
		.content-group {
			max-width: 320px;
			@include media-breakpoint-up(md){
				max-width: 423px;
			}
			p:first-of-type{
				margin-bottom: 40px;
				line-height: 25px;
			}
		}
		.button-group .wp-block-group__inner-container {
			display: flex;
			flex-direction: column;
			@include media-breakpoint-up(md){
				flex-direction: row;
			}
			.wp-block-button .wp-block-button__link{
				min-width: 280px;
				@include media-breakpoint-up(md){
					min-width: 100%;
				}
			}
			.wp-block-button:first-of-type{
				margin-bottom: 16px;
				@include media-breakpoint-up(md){
					margin-right: 22px;
				}
			}
			.wp-block-button.is-style-outline{
				.wp-block-button__link{
					@include button-variant(rgba(3,2,2,0.25), $white, rgba(3,2,2,0.5));
				}
			}
		}
	}
	&.resource-hero {
		bottom: 600px;
		min-height: 600px;
		h1 {
			color: white;
		}
	}
}

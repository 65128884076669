.gradient-overlay {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
  &.gradient-left {
    &:before {
      background: linear-gradient(to left, rgba($black, 0.55) 5%, rgba($black, 0.4) 15%, rgba($black, 0) 80%);
    }
  }
  &.gradient-right {
    &:before {
      background: linear-gradient(to right, rgba($black, 0.55) 5%, rgba($black, 0.4) 15%, rgba($black, 0) 80%);
    }
  }
}
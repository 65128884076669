/**
 * Legacy Dealer Locator Styles
 * Attempting to phase these out with appropriate bootstrap classing and more minimal styles
 */
#dealer-finder {
  position: relative;
  h1{
    margin-bottom: 40px;
  }
  .filter-bar{
    .filter-bar-top{
      margin-top: 60px;
      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
      }
      .use-my-location{ 
        white-space: nowrap;
        width: auto;
        i {
          margin-right: 4px;
        }
      }
      .input-group{
        max-width: 100%;
        flex-wrap: nowrap;
      } 
    }
    .filter-bar-bottom-wrap{
      .filter-button-toggle{
        display: none;
        margin-bottom: 12px;
        width: 100%;
        @include media-breakpoint-down(md) {
          display: block;
        }
      }
      .chevron{
        margin-left: 8px;
        transition: transform .2s linear;
        transform: rotate(90deg);
      }
      .collapsed .chevron{
        transform: rotate(0deg);
      }
    }
    .filter-bar-bottom{
      // background-color: theme-color('light');
      // color: $white;

      .filter-bar-bottom-inner{
        padding: 16px 20px 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media-breakpoint-down(md) {
          flex-wrap: wrap;
        }
      }
      select.form-select-input {
        -webkit-appearance: none;
      }
      .select-wrapper {
        position: relative;
        &:after {
          content: "\f0d7";
          font-size: 1rem;
          font-family: "Font Awesome 5 Pro";
          top: 0;
          font-weight: 900;
          right: 10px;
          position: absolute;
          color: $black;
          height: 100%;
          display: flex;
          align-items: center;
          pointer-events: none;
        }
      }
      fieldset{
        @include media-breakpoint-down(md) {
          flex-basis: 100%;
          margin-bottom: 20px;
          text-align: center;
          .form-group {
            justify-content: space-between;
            // justify-content: center;
          }
        }
        @include media-breakpoint-down(xs) {
          .form-group {
            flex-direction: column;
            justify-content: center;
          }
        }
        &.dealer-tier, &.financing {
          flex: 0 0 auto;
          margin-right: 20px;
		.gold-tier .icon-fire{
            &:before {
              content: url("../assets/gold_flame.svg");
              display: inline;
              float: left;
              width: 20px;
              margin-right: 5px;
              line-height: 1;
            }
          }
          @include media-breakpoint-down(md) {
            flex: 0 0 100%;
            margin-right: 0;
            text-align: left;
            flex-basis: 100%;
          }
        }
      }
      .form-text,.form-group{
        margin: 0;
      }
      .select-wrapper {
        @include media-breakpoint-down(xs) {
          width: 100%;
        }
      }
      select{
        width: 140px;
        @include media-breakpoint-down(md) {
          width: 275px;
        }
        @include media-breakpoint-down(sm) {
          height: auto;
        }
        @include media-breakpoint-down(xs) {
          width: 100%;
        }
      }
      select#dealer_category{
        width: 200px;
        @include media-breakpoint-down(md) {
          width: 275px;
        }
        @include media-breakpoint-down(xs) {
          width: 100%;
        }
      }
      .form-text-label{
        margin-right: 10px;
      }
      label.btn{
        margin-bottom: 0;
        margin-top: 0;
        &.has-tooltip {
          i {
            position: relative;
            bottom: 5px;
          }
        }
      }
    }
  }
  .results-wrapper{
    position: relative;
  }
  .list-wrapper{
    background-color: transparent;
    width: 500px;
    max-width: 40%;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    background-color: $white;
    max-height: calc(100% - 20px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px 25px;
    @include media-breakpoint-down(md) {
      position: static;
      width: auto;
      max-width: 100%;
    }
    .list-header{
      // @include font-size(12px);
      // line-height: 17px;
      // text-transform: uppercase;
      // color: $gray-800;
      // font-weight: 700;

      padding-bottom: 14px;
      border-bottom: 1px solid;
      margin-bottom: 30px;
    }

  }
  .list-wrapper, #dealer-map{
    article{
      position: relative;
      // color: $gray-800;
      padding-bottom: 20px;
      margin-bottom: 30px;
      border-bottom: 1px dotted;
      border-bottom-width: 1px;
      // font-family: $font-family-base;
      &.dealer_tier-gold{
        &:before{
          content: url("../assets/gold_flame.svg");
          width: 20px;
          display: block;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      .entry-header{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 14px;
        margin-right: 20px;
        .dealer-distance{
          // @include font-size(12px);
          white-space: nowrap;
        }
      }
      .entry-title{
        // @include font-size(20px);
        @include media-breakpoint-down(md) {
          // line-height: 22px;
        }
      }
      .dealer-distance{
        flex-basis: 100%;
        text-align: right;
        @include media-breakpoint-down(md){
          text-align: left;
        }
      }
      .dealer-contact{
        display: flex;
        justify-content: space-between;
        @include media-breakpoint-down(md) {
          justify-content: flex-start;
          flex-wrap: wrap;
        }
        & > div { // added so the phone number doesn't touch the contact button on some pin popups
          @include media-breakpoint-down(md) {
            flex: 0 0 100%;
          }
          &:not(.contact-col) {
            & > p {
              margin-right: 10px;
              margin-bottom: 10px;
            }
          }
        }
        .dealer-contact-btn {
          cursor: pointer;
        }
      }
      .dealer-product-lines{
        margin-bottom: 8px;
      }
      .dealer-address{
        margin-bottom: 14px;
      }
      .contact-col{
        text-align: right;
        @include media-breakpoint-down(md) {
          text-align: left;
        }
      }
      .btn{
        white-space: nowrap;
      }
    }
  }
  #dealer-map{
    height: 800px;
    width: 100%;
    @include media-breakpoint-down(md) {
      height: 0;
      padding-bottom: 66%;
    }
    .gm-style {
      .gm-style-iw-c {
        border-radius: 0px;
      }
    }
    article{
      margin-bottom: 0;
      padding: 0 15px; 
      border-bottom: 0;
      // min-width: 325px;
      @include media-breakpoint-up(md) {
        min-width: 325px;
      }
      max-width: 375px;
      margin: 20px 15px;
      padding: 0;
      .row{
        margin: 0;
      }
      div[class*="col"]{
        padding: 0;
      }

      .entry-title{
        // @include font-size(20px);
        // @include media-breakpoint-down(md) {
        //   line-height: 22px;
        // }
      }

      .dealer-address{
        margin-bottom: 8px;
        @include media-breakpoint-down(md){
          margin-bottom: 4px;
          // @include font-size(14px);
        }
      }

      .dealer-contact{
        @include media-breakpoint-down(md) {
          flex-wrap: wrap;
        }
        > *{
          @include media-breakpoint-down(md){
            flex-basis: 100%;
            text-align: left;
          }
          &:not(:first-child){
            @include media-breakpoint-down(md){
              margin-top: 10px;
            }
          }
        }
      }
      .btn{
        @include media-breakpoint-down(md){
          // padding: .25rem .75rem;
        }
      }
    }
  }
}
#dealer-contact-modal {
  z-index: 100000;
  .modal-dialog {
    max-width: 100%;
    z-index: 100000;
    .modal-content {
      z-index: 10000;
      @include media-breakpoint-down(md) {
        position: absolute;
        top: 0;
      }
    }
    @include media-breakpoint-up(lg) {
      width: 800px;
    }
    div.gform_wrapper .gfield{
      textarea,input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]){
        &,&.large{
          // @include font-size(16px);
        }
      }
    } 
    input[type=submit] {
      text-transform: uppercase;
    }
    select{
      height: auto;
    }
  }
}
#hs-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,.2);
  opacity: 0;
  pointer-events: none;
  z-index: 100;
  text-align: center;
  &.loading{
    pointer-events: all;
    opacity: 1;
  }
  i{
    color: $white;
    margin-top: 240px;
  }
}

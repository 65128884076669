.bytesco-block.hero-carousel-block {
  &.apply-negative-top-margin{
		margin-top: -120px!important;
  }
  .carousel {
    z-index: 0;
  }
  .carousel-item {
    min-height: 560px;
    height: 650px;
    background-size: cover;
    background-position: center center;
  }
	.content-group,
	.button-group{
		padding-left: 21px;
	}
	.content-group{
		padding-top: 72px;
		@include media-breakpoint-up(md){
			padding-top: 86px;
		}
		max-width: 320px;
		@include media-breakpoint-up(md){
			max-width: 423px;
		}
		p:first-of-type{
			margin-bottom: 40px;
		}
	}
	.button-group{
		display: flex;
		flex-direction: column;
		@include media-breakpoint-up(md){
			flex-direction: row;
		}

		.btn:first-of-type{
			margin-bottom: 16px;
			@include media-breakpoint-up(md){
				margin-right: 22px;
			}
		}
		.btn.btn-secondary{
			max-height: 60px;
			@include button-variant(rgba(3,2,2,0.25), $white, rgba(3,2,2,0.5));
		}
	}
}

.woocommerce-products-compare-compare-button {
  label {
    color: theme-color( 'neutral-dark' );
    @include font-size(15px);
    @include transition($transition-base);
    .icon {
      color: theme-color( 'red' );
      @include font-size(15px);
      @include transition($transition-base);
    }
    @include hover-focus-active {
      color: $link-hover-color;
      @include transition($transition-base);
      .icon {
        @include transition($transition-base);
        color: $link-hover-color;
      }
    }
  }
  
}
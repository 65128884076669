.bytesco-block.feature-call-out-block {
	.attribute-row{
		//text-align: center;
	}
	.spec-attribute-row{
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		@include media-breakpoint-up(md){
			padding: 0;
		}
	}
  .attribute {
		&.spec-attribute{
			flex: 0 0 100%;
			align-items: center;
			padding-left: calc(1.5rem + 15px);
      padding-right: calc(1.5rem + 15px);
      @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
      }
			.spec-attribute-inner{
        width: 50%;
        @include media-breakpoint-down(md) {
          width: 70%;
        }
			}
		}
		flex: 0 0 50%;
		margin: 0 auto;
		align-items: center;
		@include media-breakpoint-up(md){
			flex-grow: 1;
			flex-basis: 0;
			&.spec-attribute{
				flex: 0 0 33%;
				padding-left: 0;
				padding-right: 0;
				.spec-attribute-inner{
					width: 100%;
				}
			}
		}
    .icon {
      @include font-size(24px);
      vertical-align: sub;
      color: $black;
    }
  }
  .attribute-term {
    font-weight: 100;
    font-family: $font-family-sans-serif;
    @include font-size(80px);
    margin-top: -37px;
    margin-bottom: 0;
    z-index: -1;
  }
  .attribute-unit {
    color: #5F6058;
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -2em;
    margin-bottom: 40px;

  }
  .attribute-taxonomy, .section-title {
    font-weight: $font-weight-bold;
    @include font-size(17px);
    color: theme-color( 'bold-dark' );
  }
  .spec-item {
    color: theme-color( 'neutral-dark' );
    @include font-size(14px);
  }
  .spec-item-label {
    font-weight: $font-weight-bold;
  }

  .accordion {
    .card-header {
      .card-title {
        color: theme-color( 'neutral-dark' );
        @include font-size(15px);
        &:after {
          content: $hearthstone-icon-chevron-down;
          font-family: $icomoon-font-family;
          @include transition($transition-base);
          display: inline-block;
          margin-left: 5px;
          line-height: 1;
        }
      }
      &[aria-expanded=true] {
        .card-title {
          &:after {
            transform: rotate(180deg);
            @include transition($transition-base);
          }
        }
      }
    }
  }
}

.product-navigation{
	z-index: 1019;
	margin: 2rem calc(50% - 50vw);
	width: 100vw;
	background: transparent;
	@include media-breakpoint-up(md) {
		position: sticky;
		top: 84px;
		.admin-bar & { //additional offset if WP admin bar is present
			top: 116px;
		}
	}
	.product-menu-wrapper{
		max-width: 1457px;
	}
	.menu-item a {
		color: $white;
		padding-top: 20px;
		padding-bottom: 20px;
		&:hover, &:active, &:focus {
			text-decoration: underline;
			color: theme-color( 'bold-light' );
		}
		@include media-breakpoint-down(md){
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
}

.bytesco-block.fifty-fifty-content-block {
	h2{
		margin-bottom: 30px;
	}
	&:not(.overlap-previous) {
		&.add-content-padding {
			.content-col {
				padding-top: 147px;
				padding-bottom: 147px;
				@include media-breakpoint-down(md){
					padding-top: 50px;
					padding-bottom: 50px;
				  }
			}
		}
	}
}

// Gutenberg two column block
.bytesco-block.wp-block-columns {
	@include make-row();
	margin-right: 0 !important;
	margin-left: 0 !important;
	&.overlap-previous {
		position: relative;
		bottom: 7rem;
		margin-bottom: -7rem;
		background-color: $white;
		padding: 4rem 2rem;
		@include media-breakpoint-up(lg) {
			padding: 4rem 6rem;
		}
	}
	& > .wp-block-column {
		@include make-col-ready();
		@extend .col;
		padding-left: $grid-gutter-width;
		padding-right: $grid-gutter-width;
		@include make-container-max-widths();
		@include media-breakpoint-down(md) {
			@include make-col(12);
			padding-left: $grid-gutter-width/2;
			padding-right: $grid-gutter-width/2;
		}
		margin: 0;
	}
}

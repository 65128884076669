html {
  overflow-x: hidden;
}
.bytesco-block {
  &.alignwide,
  &.alignfull {
    margin: $bytesco-block-spacing calc(50% - 50vw);
    max-width: 100vw;
    width: 100vw;
  }
  &.overlap-previous {
		outline:none; // outline is wrapping overlap content above
    margin-bottom: -7rem + $bytesco-block-spacing;
    .container {
      position: relative;
      bottom: 7rem;
    }
    .single-column{
      padding-left: 30px;
      padding-right: 30px;
      }
    }
}

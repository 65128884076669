// Gutenbergs search block.
.wp-block-search {
  input[type="search"] {
    @include form-control;
    width: auto;
  }
  button {
    @include btn;
    @include button-variant( theme-color('primary'), theme-color('primary'));
  }
}
.form-column {
  @media (max-width: 781px){
    flex-basis: 100% !important;
    text-align: center;
    & > div > form h3 {
      text-align: center;
    }
  }
}
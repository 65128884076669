.cover-block-widget {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

div#truhybrid {
  @media (max-height: 1299px){
    padding-top: 20px;
  }
}

.wp-block-group.content-group .wp-block-group__inner-container h1 {
  @media (max-height: 1299px){
    line-height: 40px;
  }
}
// Misc. WooCommerce Styles 

.woocommerce span.onsale {
  color: $white;
  background-color: $green;
}

.woocommerce ul.products li.product .price {
  color: $green;
  del {
    opacity: 1;
  }
}
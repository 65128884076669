.modal-open .modal{
	padding-right: 0px!important;
}

#bytesco-header {
  z-index: 1020;

	.navigation-modal {
		.modal-body{
			padding-left: 0;
			padding-right: 0;
			.menu-item{
				border-bottom: 1px solid theme-color("pale-light");
				padding-left: 21px;
				padding-right: 21px;
				.menu-item{
					border:none;
					padding-left:0;
				}
			}
		}
		.modal-footer{
			border-top: none;
		}
	}
  @include media-breakpoint-up(md) {
    // utility navigation is present from md up, so compensate sticky positioning
    // position sticky will need to be polyfilled
    top: -33px;
    // We've decided that mobile will not be sticky, and bootstrap had no classing for that!
    position: sticky;
  }
  .admin-bar & {
    @include media-breakpoint-up(md) {
      top: -1px;
     }
  }
  #wrapper-navbar {
    max-width: 1457px; // purposefully doesn't conform to bootstrap

		.navbar-toggler{
			border:none;
		}
  }
  .custom-logo-link img {
    max-width: 176px; // upper-bounds constraining to match comp
  }
  @include media-breakpoint-only(md) {
    .primary-navbar {
      flex-wrap: wrap;
      .site-logo {
          flex: 100%;
          text-align: center;
          margin-top: 10px;
      }
    }
  }
}

//conditionally display on front page
.homepage-hero-image{
  position: absolute;
  top:0;
  left: 0;
  right: 0;
  bottom: 575px;
  min-height: 575px;
  background-size: cover;
  background-position: right center;
  @include media-breakpoint-up(md){
    background-position: center;
    bottom: 710px;
  }
}

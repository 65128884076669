.btn {
  .input-group-append & {
    // we need to attempt to match the padding of the input we're appended to
    padding: 0.375rem 0.75rem; // we don't have equivalent spacers defined
  }
  // we've come across the unfortunate situation of having an <a> inside of a btn div (next / prev post buttons)
  a {
    color: inherit;
  }
}

.wp-block-button .wp-block-button__link {
  // we're copying the button styling except for color, so this will play nice with gutenberg color selections
  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  text-align: center;
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: if($enable-pointer-cursor-for-buttons, pointer, null);
  user-select: none;
  border: $btn-border-width solid;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);

  padding-bottom: calc(#{$btn-padding-y} - 1px); // 1px than $btn-padding-y

  @include hover() {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }
  .has-very-light-gray-background-color {
    background: $white;

  }
}

.wp-block-button .wp-block-button__link:not([class*="has-"]) {
  @include button-variant( map-get( $theme-colors, 'primary'), map-get( $theme-colors, 'primary') );
}

.wp-block-button.is-style-outline .wp-block-button__link:not([class*="has-"]), .wp-block-button .has-very-light-gray-background-color.has-very-dark-gray-color{
  @include button-variant( $white, map-get( $theme-colors, 'pale-light' ), map-get( $theme-colors, 'bold-dark' ));
}


.skip-link {
  @include btn;
  @include button-variant($light, $light);
  &:focus {
    position: fixed;
    top: 8px;
    left: $grid-gutter-width / 2;
    right: auto;
    // This is BS's highest z-index and the z-index of the wp-admin bar. Take
    // whatever is bigger and add 10 so we know we are on top.
    z-index: max($zindex-tooltip, 99999) + 10;
  }
}

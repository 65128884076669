.utility-widget-area {
  .menu-item {
    &:first-child {
      border-left: 1px solid theme-color('pale-light');
    }
    &:last-child {
      border-right: 1px solid theme-color('pale-light');
    }
    select {
      .utility-header & {
        margin: 9px 25px;
        border: none;
				background: transparent;
				background-color: transparent;
      }
			color: theme-color('light');
			@include media-breakpoint-down(sm){
				color: $black;
				margin-top: 15px;
				margin-bottom: 12px;
				padding-left: 5px;
			}
    }
    a {
			color: theme-color("light");
			@include media-breakpoint-down(sm){
				color: $black;
				padding-top: 15px;
				padding-bottom: 12px;
				padding-left: 5px;
			}
			@include hover-focus-active {
				color: theme-color("bold-light");
				@include media-breakpoint-down(sm){
					color: rgba(14,14,14,.7);
					text-decoration: none;
				}
			}
    }
  }
	@include media-breakpoint-down(sm){
		.menu-item{
			&:first-child {
				border-left: 1px solid transparent;
			}
			&:last-child {
				border-right: 1px solid transparent;
			}
			select{
				margin-left: -3px;
				border: none;
				background: transparent;
				background-color: transparent;
			}
		}
		li{
			@include font-size(17px);
		}
	}
}

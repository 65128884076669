/*!
 * Font Awesome Pro 5.11.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */

@import "../../src/sass/fontawesome/fontawesome";

// Import variations of Font Awesome. 
@import "../../src/sass/fontawesome/regular";
@import "../../src/sass/fontawesome/solid";
@import "../../src/sass/fontawesome/light";
@import "../../src/sass/fontawesome/duotone";
@import "../../src/sass/fontawesome/brands";


#wrapper-footer-full {
    background: transparent; // starter presets to a gray
}
#footer-full-content{
	margin-bottom: 40px;
	@include media-breakpoint-up(md){
		margin-bottom: 60px;
	}
}
.site-footer {
    background-color: theme-color('bold-dark');
		position: absolute;
		left: 0;
		right: 0;
		top: auto;
		&.wrapper{
			padding-top: 40px;
			padding-bottom: 102px;
			@include media-breakpoint-up(md){
				padding-top: 105px;
				padding-bottom: 110px;
			}
		}
		.menu{
			@extend .list-unstyled;
			margin-bottom: 0;
			line-height: $footer-menu-line-height;
		}

		.footer-bottom{
			padding-top: 25px;
			@include media-breakpoint-up(md){
				padding-top: 20px;
			}
		}
    .footer-left{
        .social-links {
            .icon {
                // custom font sizing needed here
                font-size: 1.5rem;
                margin-right: 1rem;
            }
        }
    }
}

.footer-third {
	margin-bottom: 1rem;
}

#footer-bottom-left{
	.textwidget {
		line-height: $footer-menu-line-height;
	}
	.textwidget.custom-html-widget{
		line-height: $footer-text-line-height;
		@include font-size($footer-font-size);
		margin-top: 16px;
		@include media-breakpoint-up(md){
			margin-top: 5px;
		}
	}
}

#footer-bottom-right {
	width: 100%;
	@include media-breakpoint-up(md){
		width: auto;
	}
    .menu {
        .menu-item {
					@include media-breakpoint-up(md){
						display: inline;
					}
            @extend .col;
        }
    }
}

.aweb-container {
	display: flex;
	justify-content: flex-end;
}

.menu-footer-bottom-navigation-container {
	@include media-breakpoint-down(md){
		font-size: x-small;
	}
}
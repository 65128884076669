/**
 * New Dealer Locator Styles
 */
.dealer-locator-block {
    .location-search-input {
        flex: 0 1 400px;
	}
	.dealer-loc-label{
		position: absolute;
		bottom: 51px;
		left: 10.5px;
	}
	.use-my-location {
		@include media-breakpoint-down(md) {
			// need to mimic appended button styling
			padding: 0.375rem 0.75rem;
			border-color: theme-color( 'pale-light' );
		}
	}
    .use-my-location-text {
        @include media-breakpoint-down(md) {
            // only hide the text on mobile
            @include sr-only;
        }
    }
	.checkbox-label {
		&::after {
			content: '';
			display: inline-block;
			width: 15px;
			height: 15px;
			background: theme-color( 'white' );
			border: 1px solid theme-color( 'light' );
		}
		&:focus, &:hover, &.focus {
			box-shadow: none;
			&::after {
				border-color: theme-color( 'bold-light' );
				background: theme-color( 'light' );
			}
		}
		&.checked::after {
			background: theme-color( 'bold-light' );
		}
	}
}

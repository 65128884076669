html {
  font-size: #{$font-size-base-px-equivelent}px;
}

h2{
	margin-bottom: 24px;
	letter-spacing: 0;
	@include media-breakpoint-up(md){
		@include font-size($h2-desktop-font-size);
		letter-spacing: 0;
		line-height: $h2-desktop-line-height;
	}
}

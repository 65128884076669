.bytesco-block.product-grid-block {
  .container {
    & > .row {
      .model-number {
        color: $black;
      }
      &:not(:last-of-type) {
        margin-bottom: $bytesco-block-spacing;
        @include media-breakpoint-up(md) {
          margin-bottom: $bytesco-block-spacing*4;
        }
      }
      &:nth-child(even) {
        .col-image {
          @include media-breakpoint-up(lg) {
            order: 2;
          }
        }
        .col-content {
          @include media-breakpoint-up(lg) {
            order: 1;
          }
          @include media-breakpoint-up(xl) {
            padding-right: 8rem !important;
          }
        }
      }
      &:nth-child(odd) {
        .col-content {
          @include media-breakpoint-up(xl) {
            padding-left: 8rem !important;
          }
        }
      }
    }
    .mb-5 {
      @include media-breakpoint-down(md){
        margin-bottom: 1rem !important;
      }
    }
  }
  .sku {
    @include font-size(12px);
  }
  .col-image {
    background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
  }
  .card-link, .woocommerce-products-compare-compare-button {
    flex-basis: 100%;
    @include media-breakpoint-up(xl) {
      flex-basis: 50%;
    }
  }
}

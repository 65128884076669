.bytesco-block.product-cards-block {
  .card {
    border-color: rgba($gray-200, 0.5);
  }
	.card-img {
		height: 270px;
		background-position: top center;
		background-size: contain;
		background-repeat: no-repeat;
	}
  .card-body {
    border-top-color: rgba($gray-200, 0.5) !important;
  }
  .card-title {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-bold;
    @include font-size(17px);
    color: $black;
  }
  .card-text {
    color: theme-color( 'neutral-dark' );
    @include font-size(15px);
  }
  .card-footer {
    &.flex-xl-column {
      .card-link {
        @include media-breakpoint-up(xl) {
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.icon {
    min-width:20px;
    font-size: 20px;
    &.large {
        min-width: 35px;
        min-height: 35px;
        font-size: 22px;
    }
    &.solid {
        background-color: theme-color( 'primary' );
        color: $white;
        border: solid 1px theme-color( 'primary' );
        transition: none .5s;
        transition-property: background-color, color, border-color;
        &:hover {
            background-color: $white;
            color: theme-color( 'primary' );
            border-color: theme-color( 'pale' );
        }
    }
}

.fancy-link {
  color: $black;
  @include font-size(15px);
  font-weight: $font-weight-medium;
  &:after {
    content: '\f054';
    font-family: "Font Awesome 5 Pro";
    color: $link-color;
    text-decoration: underline;
    text-decoration-color: white;
    margin-left: 10px;
    @include transition($transition-base);
  }
  &.download {
    &:after {
      content: '\e95a';
      font-family: $icomoon-font-family;
    }
  }
  @include hover-focus-active {
    &:after {
      margin-left: 15px;
      @include transition($transition-base);
    }
  }

}
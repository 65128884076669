.bytesco-block.image-text-overlay-block {

  background-position: left center;
	background-size: cover;
	@include media-breakpoint-up(md){
		padding-top: 252px;
		padding-bottom: 117px;
		background-position: center center;
	}
  .icon {
    @include font-size(25px);
    color: theme-color( 'primary' );
  }
  .text-overlay {
    background-color: rgba($white, 0.95);
		padding: 30px;
		@include media-breakpoint-down(md) {
			margin: 2rem 1rem;
		}
		@include media-breakpoint-up(md){
			margin-top: 244px;
			margin-bottom: -244px;
			min-width: fit-content;
			margin: 0;
		}
  }
	// apply corresponding margin to a 50/50 block that directly follows
	& + .fifty-fifty-content-block{
		margin-top: 244px;
		@include media-breakpoint-up(md){
			margin-top: 0;
		}
	}
}

@mixin breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  padding: $breadcrumb-padding-y $breadcrumb-padding-x;
  margin-bottom: $breadcrumb-margin-bottom;
  list-style: none;
  background-color: $breadcrumb-bg;
  @include border-radius($breadcrumb-border-radius);
  color: $breadcrumb-divider-color;
  
  a {
    color: $link-color;
    @include hover-focus {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
    }
  }
  
  & > span {
    // The separator between breadcrumbs (by default, a forward-slash: "/")
    + span {
      padding-left: $breadcrumb-item-padding;

      &::before {
        display: inline-block; // Suppress underlining of the separator in modern browsers
        padding-right: $breadcrumb-item-padding;
        color: $breadcrumb-divider-color;
        content: $breadcrumb-divider;
      }
    }

    // IE9-11 hack to properly handle hyperlink underlines for breadcrumbs built
    // without `<ul>`s. The `::before` pseudo-element generates an element
    // *within* the .breadcrumb-item and thereby inherits the `text-decoration`.
    //
    // To trick IE into suppressing the underline, we give the pseudo-element an
    // underline and then immediately remove it.
    + span:hover::before {
      text-decoration: underline;
    }
    // stylelint-disable-next-line no-duplicate-selectors
    + span:hover::before {
      text-decoration: none;
    }
    
    &.current-item {
      color: $breadcrumb-active-color;
    }
    
    &:before {
      display: none !important; // Bootstrap tries to add its own separator.
    }
    
    &:not(:last-child) {
      padding-right: $breadcrumb-item-padding;
    }
  }
}
// Temporarily disabling compare at PM request
.woocommerce-products-compare-compare-button {
    display: none;
}
.woocommerce-products-compare-content {
    margin: 0 auto;
    table {
        margin: 0 auto;
    }
    .add_to_cart_button, .single_add_to_cart_button {
        background: #E21B23;
        color: #fff;
        &:hover {
            background-color: #c0171e;
            border-color: #b4161c;
        }
    }
    &.no-products {
        height: 30vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p:first-child {
            font-size: 20px;
        }
    }
    a.button.wc-backward {
        @include button-variant( map-get( $theme-colors, 'primary'), map-get( $theme-colors, 'primary') );
        padding: 12px 15px;
    }
    .product a.product-link img {
        height: 230px;
        width: 286px;
    }
}
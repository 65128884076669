// Use this file to overwrite the basic Bootstrap variables and add your own variables.

// Colors
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-550: #8E94A9;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #0E0E0E;

$green-500: #51B241;
$red-500: #E21B23;
//$blue:    #0060c1;
$light-blue: #2E96FF;
//$green:   #186329;

$theme-colors: (
    "primary": #E21B23,
    "pale-light": #EFEFEF,
    "light": #939DA6,
    "bold-light": #CBCFD3,
    "red": #E21B23,
    "neutral-dark": #5F6058,
    "bold-dark": #272F36,
    "popover-gray": #939DA6,
    "leaf-green" : #51B241,
    "gold": #e8c51d
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1258px
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1300px
);
$spacer: 1rem;
$spacers: (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
		3.5: ($spacer * 1.25),
    4: ($spacer * 1.5),
		4.25: ($spacer * 2),
    4.5: ($spacer * 2.3),
    5: ($spacer * 3)
);

//slick Carousel
$slick-font-path: "/wp-content/themes/hearthstone/fonts/";

// General Readius for buttons, popup windows, etc
$border-radius: 2px;

// Nav
$navbar-dark-color:                 $white;
$navbar-dark-hover-color:           $white;
$navbar-dark-active-color:          $white;
$navbar-dark-disabled-color:        $white;
$navbar-dark-toggler-border-color:  $white;

$navbar-light-color: $black;

$utility-nav-font-size: 14px;
$utility-nav-spacing-y: 0.5rem;

// Breadcrumbs
$breadcrumb-divider-color: $gray-800;

// Buttons
$btn-padding-y: map-get( $spacers, 3.5);
$btn-padding-x: map-get( $spacers, 4.25);

// Forms
$input-btn-focus-box-shadow-color: map-get( $theme-colors, 'pale-light' );
$input-border-color: map-get( $theme-colors, 'pale-light' );
$input-focus-border-color: map-get( $theme-colors, 'bold-dark');
$input-btn-focus-box-shadow: inset 0 0 0 4px $input-btn-focus-box-shadow-color;

// Bytes.co Blocks
$bytesco-block-spacing: 2rem;

// Typography
$font-size-base-px-equivelent: 14; // This is the base font size in pixels. Do not include `px`.
$rfs-rem-value: $font-size-base-px-equivelent; // This makes the rem calculations correct. See https://github.com/twbs/rfs
$line-height-base: 1.75;

$font-weight-semi-bold: 600;
$font-weight-medium: 500;

// These are some colors that WordPress uses in their admin area.
// https://make.wordpress.org/design/handbook/design-guide/foundations/colors/
$wp-ultra-dark-gray: #191E23;
$wp-dark-gray: #23282D;
$wp-base-gray: #32373C;
$wp-green: #46B450;
$wp-red: #DC3232;
$wp-blue: #0073AA;
$wp-md-blue: #00A0D2;

// Set up font awesome
$fa-font-path: "../fonts";

// Icomoon Icons
// These are from the variables.scss file included in the downloaded font zip file
// $icomoon-font-family: "icomoon-icons";
$icomoon-font-path: "/wp-content/themes/hearthstone/fonts/hearthstone";
$icomoon-font-family: "hearthstone-icons";

$hearthstone-icon-leaf: "\e900";
$hearthstone-icon-fire: "\e901";
$hearthstone-icon-activity: "\e902";
$hearthstone-icon-airplay: "\e903";
$hearthstone-icon-alert-circle: "\e904";
$hearthstone-icon-alert-octagon: "\e905";
$hearthstone-icon-alert-triangle: "\e906";
$hearthstone-icon-align-center: "\e907";
$hearthstone-icon-align-justify: "\e908";
$hearthstone-icon-align-left: "\e909";
$hearthstone-icon-align-right: "\e90a";
$hearthstone-icon-anchor: "\e90b";
$hearthstone-icon-aperture: "\e90c";
$hearthstone-icon-archive: "\e90d";
$hearthstone-icon-arrow-down: "\e90e";
$hearthstone-icon-arrow-down-circle: "\e90f";
$hearthstone-icon-arrow-down-left: "\e910";
$hearthstone-icon-arrow-down-right: "\e911";
$hearthstone-icon-arrow-left: "\e912";
$hearthstone-icon-arrow-left-circle: "\e913";
$hearthstone-icon-arrow-right: "\e914";
$hearthstone-icon-arrow-right-circle: "\e915";
$hearthstone-icon-arrow-up: "\e916";
$hearthstone-icon-arrow-up-circle: "\e917";
$hearthstone-icon-arrow-up-left: "\e918";
$hearthstone-icon-arrow-up-right: "\e919";
$hearthstone-icon-at-sign: "\e91a";
$hearthstone-icon-award: "\e91b";
$hearthstone-icon-bar-chart: "\e91c";
$hearthstone-icon-bar-chart-2: "\e91d";
$hearthstone-icon-battery: "\e91e";
$hearthstone-icon-battery-charging: "\e91f";
$hearthstone-icon-bell: "\e920";
$hearthstone-icon-bell-off: "\e921";
$hearthstone-icon-bluetooth: "\e922";
$hearthstone-icon-bold: "\e923";
$hearthstone-icon-book: "\e924";
$hearthstone-icon-book-open: "\e925";
$hearthstone-icon-bookmark: "\e926";
$hearthstone-icon-box: "\e927";
$hearthstone-icon-briefcase: "\e928";
$hearthstone-icon-calendar: "\e929";
$hearthstone-icon-camera: "\e92a";
$hearthstone-icon-camera-off: "\e92b";
$hearthstone-icon-cast: "\e92c";
$hearthstone-icon-check: "\e92d";
$hearthstone-icon-check-circle: "\e92e";
$hearthstone-icon-check-square: "\e92f";
$hearthstone-icon-chevron-down: "\e930";
$hearthstone-icon-chevron-left: "\e931";
$hearthstone-icon-chevron-right: "\e932";
$hearthstone-icon-chevron-up: "\e933";
$hearthstone-icon-chevrons-down: "\e934";
$hearthstone-icon-chevrons-left: "\e935";
$hearthstone-icon-chevrons-right: "\e936";
$hearthstone-icon-chevrons-up: "\e937";
$hearthstone-icon-chrome: "\e938";
$hearthstone-icon-circle: "\e939";
$hearthstone-icon-clipboard: "\e93a";
$hearthstone-icon-clock: "\e93b";
$hearthstone-icon-cloud: "\e93c";
$hearthstone-icon-cloud-drizzle: "\e93d";
$hearthstone-icon-cloud-lightning: "\e93e";
$hearthstone-icon-cloud-off: "\e93f";
$hearthstone-icon-cloud-rain: "\e940";
$hearthstone-icon-cloud-snow: "\e941";
$hearthstone-icon-code: "\e942";
$hearthstone-icon-codepen: "\e943";
$hearthstone-icon-codesandbox: "\e944";
$hearthstone-icon-coffee: "\e945";
$hearthstone-icon-columns: "\e946";
$hearthstone-icon-command: "\e947";
$hearthstone-icon-compass: "\e948";
$hearthstone-icon-copy: "\e949";
$hearthstone-icon-corner-down-left: "\e94a";
$hearthstone-icon-corner-down-right: "\e94b";
$hearthstone-icon-corner-left-down: "\e94c";
$hearthstone-icon-corner-left-up: "\e94d";
$hearthstone-icon-corner-right-down: "\e94e";
$hearthstone-icon-corner-right-up: "\e94f";
$hearthstone-icon-corner-up-left: "\e950";
$hearthstone-icon-corner-up-right: "\e951";
$hearthstone-icon-cpu: "\e952";
$hearthstone-icon-credit-card: "\e953";
$hearthstone-icon-crop: "\e954";
$hearthstone-icon-crosshair: "\e955";
$hearthstone-icon-database: "\e956";
$hearthstone-icon-delete: "\e957";
$hearthstone-icon-disc: "\e958";
$hearthstone-icon-dollar-sign: "\e959";
$hearthstone-icon-download: "\e95a";
$hearthstone-icon-download-cloud: "\e95b";
$hearthstone-icon-droplet: "\e95c";
$hearthstone-icon-edit: "\e95d";
$hearthstone-icon-edit-2: "\e95e";
$hearthstone-icon-edit-3: "\e95f";
$hearthstone-icon-external-link: "\e960";
$hearthstone-icon-eye: "\e961";
$hearthstone-icon-eye-off: "\e962";
$hearthstone-icon-facebook: "\e963";
$hearthstone-icon-fast-forward: "\e964";
$hearthstone-icon-feather: "\e965";
$hearthstone-icon-figma: "\e966";
$hearthstone-icon-file: "\e967";
$hearthstone-icon-file-minus: "\e968";
$hearthstone-icon-file-plus: "\e969";
$hearthstone-icon-file-text: "\e96a";
$hearthstone-icon-film: "\e96b";
$hearthstone-icon-filter: "\e96c";
$hearthstone-icon-flag: "\e96d";
$hearthstone-icon-folder: "\e96e";
$hearthstone-icon-folder-minus: "\e96f";
$hearthstone-icon-folder-plus: "\e970";
$hearthstone-icon-framer: "\e971";
$hearthstone-icon-frown: "\e972";
$hearthstone-icon-gift: "\e973";
$hearthstone-icon-git-branch: "\e974";
$hearthstone-icon-git-commit: "\e975";
$hearthstone-icon-git-merge: "\e976";
$hearthstone-icon-git-pull-request: "\e977";
$hearthstone-icon-github: "\e978";
$hearthstone-icon-gitlab: "\e979";
$hearthstone-icon-globe: "\e97a";
$hearthstone-icon-grid: "\e97b";
$hearthstone-icon-hard-drive: "\e97c";
$hearthstone-icon-hash: "\e97d";
$hearthstone-icon-headphones: "\e97e";
$hearthstone-icon-heart: "\e97f";
$hearthstone-icon-help-circle: "\e980";
$hearthstone-icon-hexagon: "\e981";
$hearthstone-icon-home: "\e982";
$hearthstone-icon-image: "\e983";
$hearthstone-icon-inbox: "\e984";
$hearthstone-icon-info: "\e985";
$hearthstone-icon-instagram: "\e986";
$hearthstone-icon-italic: "\e987";
$hearthstone-icon-key: "\e988";
$hearthstone-icon-layers: "\e989";
$hearthstone-icon-layout: "\e98a";
$hearthstone-icon-life-buoy: "\e98b";
$hearthstone-icon-link: "\e98c";
$hearthstone-icon-link-2: "\e98d";
$hearthstone-icon-linkedin: "\e98e";
$hearthstone-icon-list: "\e98f";
$hearthstone-icon-loader: "\e990";
$hearthstone-icon-lock: "\e991";
$hearthstone-icon-log-in: "\e992";
$hearthstone-icon-log-out: "\e993";
$hearthstone-icon-mail: "\e994";
$hearthstone-icon-map: "\e995";
$hearthstone-icon-map-pin: "\e996";
$hearthstone-icon-maximize: "\e997";
$hearthstone-icon-maximize-2: "\e998";
$hearthstone-icon-meh: "\e999";
$hearthstone-icon-menu: "\e99a";
$hearthstone-icon-message-circle: "\e99b";
$hearthstone-icon-message-square: "\e99c";
$hearthstone-icon-mic: "\e99d";
$hearthstone-icon-mic-off: "\e99e";
$hearthstone-icon-minimize: "\e99f";
$hearthstone-icon-minimize-2: "\e9a0";
$hearthstone-icon-minus: "\e9a1";
$hearthstone-icon-minus-circle: "\e9a2";
$hearthstone-icon-minus-square: "\e9a3";
$hearthstone-icon-monitor: "\e9a4";
$hearthstone-icon-moon: "\e9a5";
$hearthstone-icon-more-horizontal: "\e9a6";
$hearthstone-icon-more-vertical: "\e9a7";
$hearthstone-icon-mouse-pointer: "\e9a8";
$hearthstone-icon-move: "\e9a9";
$hearthstone-icon-music: "\e9aa";
$hearthstone-icon-navigation: "\e9ab";
$hearthstone-icon-navigation-2: "\e9ac";
$hearthstone-icon-octagon: "\e9ad";
$hearthstone-icon-package: "\e9ae";
$hearthstone-icon-paperclip: "\e9af";
$hearthstone-icon-pause: "\e9b0";
$hearthstone-icon-pause-circle: "\e9b1";
$hearthstone-icon-pen-tool: "\e9b2";
$hearthstone-icon-percent: "\e9b3";
$hearthstone-icon-phone: "\e9b4";
$hearthstone-icon-phone-call: "\e9b5";
$hearthstone-icon-phone-forwarded: "\e9b6";
$hearthstone-icon-phone-incoming: "\e9b7";
$hearthstone-icon-phone-missed: "\e9b8";
$hearthstone-icon-phone-off: "\e9b9";
$hearthstone-icon-phone-outgoing: "\e9ba";
$hearthstone-icon-pie-chart: "\e9bb";
$hearthstone-icon-play: "\e9bc";
$hearthstone-icon-play-circle: "\e9bd";
$hearthstone-icon-plus: "\e9be";
$hearthstone-icon-plus-circle: "\e9bf";
$hearthstone-icon-plus-square: "\e9c0";
$hearthstone-icon-pocket: "\e9c1";
$hearthstone-icon-power: "\e9c2";
$hearthstone-icon-printer: "\e9c3";
$hearthstone-icon-radio: "\e9c4";
$hearthstone-icon-refresh-ccw: "\e9c5";
$hearthstone-icon-refresh-cw: "\e9c6";
$hearthstone-icon-repeat: "\e9c7";
$hearthstone-icon-rewind: "\e9c8";
$hearthstone-icon-rotate-ccw: "\e9c9";
$hearthstone-icon-rotate-cw: "\e9ca";
$hearthstone-icon-rss: "\e9cb";
$hearthstone-icon-save: "\e9cc";
$hearthstone-icon-scissors: "\e9cd";
$hearthstone-icon-search: "\e9ce";
$hearthstone-icon-send: "\e9cf";
$hearthstone-icon-server: "\e9d0";
$hearthstone-icon-settings: "\e9d1";
$hearthstone-icon-share: "\e9d2";
$hearthstone-icon-share-2: "\e9d3";
$hearthstone-icon-shield: "\e9d4";
$hearthstone-icon-shield-off: "\e9d5";
$hearthstone-icon-shopping-bag: "\e9d6";
$hearthstone-icon-shopping-cart: "\e9d7";
$hearthstone-icon-shuffle: "\e9d8";
$hearthstone-icon-sidebar: "\e9d9";
$hearthstone-icon-skip-back: "\e9da";
$hearthstone-icon-skip-forward: "\e9db";
$hearthstone-icon-slack: "\e9dc";
$hearthstone-icon-slash: "\e9dd";
$hearthstone-icon-sliders: "\e9de";
$hearthstone-icon-smartphone: "\e9df";
$hearthstone-icon-smile: "\e9e0";
$hearthstone-icon-speaker: "\e9e1";
$hearthstone-icon-square: "\e9e2";
$hearthstone-icon-star: "\e9e3";
$hearthstone-icon-stop-circle: "\e9e4";
$hearthstone-icon-sun: "\e9e5";
$hearthstone-icon-sunrise: "\e9e6";
$hearthstone-icon-sunset: "\e9e7";
$hearthstone-icon-tablet: "\e9e8";
$hearthstone-icon-tag: "\e9e9";
$hearthstone-icon-target: "\e9ea";
$hearthstone-icon-terminal: "\e9eb";
$hearthstone-icon-thermometer: "\e9ec";
$hearthstone-icon-thumbs-down: "\e9ed";
$hearthstone-icon-thumbs-up: "\e9ee";
$hearthstone-icon-toggle-left: "\e9ef";
$hearthstone-icon-toggle-right: "\e9f0";
$hearthstone-icon-tool: "\e9f1";
$hearthstone-icon-trash: "\e9f2";
$hearthstone-icon-trash-2: "\e9f3";
$hearthstone-icon-trello: "\e9f4";
$hearthstone-icon-trending-down: "\e9f5";
$hearthstone-icon-trending-up: "\e9f6";
$hearthstone-icon-triangle: "\e9f7";
$hearthstone-icon-truck: "\e9f8";
$hearthstone-icon-tv: "\e9f9";
$hearthstone-icon-twitch: "\e9fa";
$hearthstone-icon-twitter: "\e9fb";
$hearthstone-icon-type: "\e9fc";
$hearthstone-icon-umbrella: "\e9fd";
$hearthstone-icon-underline: "\e9fe";
$hearthstone-icon-unlock: "\e9ff";
$hearthstone-icon-upload: "\ea00";
$hearthstone-icon-upload-cloud: "\ea01";
$hearthstone-icon-user: "\ea02";
$hearthstone-icon-user-check: "\ea03";
$hearthstone-icon-user-minus: "\ea04";
$hearthstone-icon-user-plus: "\ea05";
$hearthstone-icon-user-x: "\ea06";
$hearthstone-icon-users: "\ea07";
$hearthstone-icon-video: "\ea08";
$hearthstone-icon-video-off: "\ea09";
$hearthstone-icon-voicemail: "\ea0a";
$hearthstone-icon-volume: "\ea0b";
$hearthstone-icon-volume-1: "\ea0c";
$hearthstone-icon-volume-2: "\ea0d";
$hearthstone-icon-volume-x: "\ea0e";
$hearthstone-icon-watch: "\ea0f";
$hearthstone-icon-wifi: "\ea10";
$hearthstone-icon-wifi-off: "\ea11";
$hearthstone-icon-wind: "\ea12";
$hearthstone-icon-x: "\ea13";
$hearthstone-icon-x-circle: "\ea14";
$hearthstone-icon-x-octagon: "\ea15";
$hearthstone-icon-x-square: "\ea16";
$hearthstone-icon-youtube: "\ea17";
$hearthstone-icon-zap: "\ea18";
$hearthstone-icon-zap-off: "\ea19";
$hearthstone-icon-zoom-in: "\ea1a";
$hearthstone-icon-zoom-out: "\ea1b";

// Typography
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;700&display=swap');
$font-family-sans-serif: 'Poppins', "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$body-color: map-get( $theme-colors, 'neutral-dark' );

$h1-font-size: 61px;
$h1-line-height: 51px;
$h2-font-size: 40px;
$h2-line-height: 40px;
$h2-desktop-font-size: 55px;
$h2-desktop-line-height: 55px;
$h3-font-size: 22px;
$h3-line-height: 29px;
$h4-font-size: 17px;
$h4-line-height: 25px;
$footer-menu-line-height: 25px;
$footer-text-line-height: 16px;
$footer-font-size: 10px;


$headings-font-weight: 700;
$headings-color: map-get( $theme-colors, 'bold-dark' );


body.page-template-nofooter-fullwidthpage {
    
     #footer-bottom-left-wrapper {
        max-width: 1457px;
        margin: auto;
        position: relative;
        bottom: 90px;
        height: 0px;
        z-index: 1000;
        margin-bottom: 0px;
        @include media-breakpoint-down(lg) {
            @include make-container();
            @include make-container-max-widths();
        }
        @media all and (max-width: 1480px) and (min-width: 1301px) {
            max-width: 1257px;
        }
}
.promo-page {
    .category-display-card {
        height: 81px;
        width: 341px;
        & > figure > a {
            padding-left: 5px;
        }
        div.wp-block-media-text__content {
            p {
                margin: 0;
            }
        }
    }
    // #footer-bottom-left {
    //     width: 100vw;
    //     position: absolute;
    //     bottom: 48px;
    //     left: 94px;
    //     color: lightgray;
    //     font-family: Poppins;
    //     font-size: 10px;
    //     letter-spacing: 0;
    //     line-height: 16px;
    // }
    }
}


.bytesco-block.product-slider {
	.content-col{
		padding-right: 30px;
		h2{
			margin-bottom: 40px;
			@include media-breakpoint-up(md){
				margin-bottom: 68px;
			}
		}
		h3{
			margin-bottom: 24px;
		}
		p{
			margin-bottom: 30px;
		}
		.fancy-link{
			margin-bottom: 14px;
			&:last-of-type{
				margin-bottom: 47px;
				@include media-breakpoint-up(lg){
					margin-bottom: 0;
				}
			}
		}
	}
	.card{
		height: auto;
		min-height: 399px!important;
		width: 292px!important;//slick slider is overriding this with inline styles
		@include media-breakpoint-up(md){
			width: 320px!important; //slick slider is overriding this with inline styles
			min-height: 456px!important; //slick slider is overriding this with inline styles
		}
	}
	.card-img{
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		width: 100%;
		height: 283px;
		@include media-breakpoint-up(md){
			height: 342px;
		}
		&:hover{
			@extend .gradient-overlay;
			&:before {
				background: rgba($black, 0.4);
			}
		}
		.product-flag {
			display: inline-block;
			height: 25px;
			min-width: 40px;
			width: fit-content;
			max-width: 28%;
			padding: 0 6px;
			margin-top: 19px;
			margin-left: 15px;
			border-radius: 2px;
			background-color: #E21B23;
			color: #FFFFFF;
			font-family: Poppins;
			font-size: 10px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 25px;
			text-align: center;
		}
	}
	.card-content{
		min-height: 115px;
	}
  .card-title {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-bold;
    @include font-size(17px);
  }
	.card-body{
		padding-top: 15px;
		padding-bottom:0;
	}
  .card-text {
    color: theme-color( 'neutral-dark' );
	}
	.card-footer {
		color: $black;
	}
	//specific to slick slider for this Block
	.slick-dotted.slick-slider{
		margin-bottom: 100px;
	}
	.slick-arrow{
		background: transparent;
	}
	.slick-prev,
	.slick-next{
		z-index: 1;
	}
	.slick-prev{
		left: 1px;
	}
	.slick-next{
		right: 1px;
	}
	.slick-prev:before,
	.slick-next:before{
		font-family: $icomoon-font-family;
		color: $black;
		background: $white;
	}
	.slick-prev:before{
		content: $hearthstone-icon-arrow-left;
	}
	.slick-next:before{
		content: $hearthstone-icon-arrow-right;
	}
	.slick-slide{
		height: auto;
		min-height: 399px!important;
		width: 292px!important;//slick slider is overriding this with inline styles
		@include media-breakpoint-up(md){
			width: 320px!important; //slick slider is overriding this with inline styles
			min-height: 456px!important; //slick slider is overriding this with inline styles
		}
	}
	.slick-dots{
		bottom: -50px;
		li{
			height: 1px;
			width: 90px;
			margin:0;
			background-color: theme-color( 'pale-light' );
			&.slick-active{
				background-color: theme-color( 'bold-dark' );
			}
			button{
				&:before{
					content: "";
				}
			}
		}
	}
}

.popover {
  background-color: $black;
  max-width: 230px;
  padding: 1rem;
  &.bs-popover-top > .arrow::before {
    border-top-color: $black;
  }
  &.bs-popover-top > .arrow::after {
    border-top-color: $black;
  }
  .popover-body {
    color: theme-color( 'popover-gray' );
  }
}
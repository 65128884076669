nav.sticky-top[aria-label="Main Menu"] {
  box-shadow: 1px 3.5px 3px rgba(52,58,64,0.1);
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.main-menu {
  .menu-item {
    & > button, & > a {
      border-bottom: solid 4px transparent; // we use border colors to indicate other states
    }
  }
  & > .menu-item {
    // first-level menu items only
    @include media-breakpoint-up(md) {
      display: flex;
    }
    & > button, & > a {
        font-weight: 500;
        margin-right: 42px; // technically this is 64 in the  comps, but I'm making consistent for now because I think it looks odd
        padding-top: 29px;
        padding-bottom: 26px;
        line-height: 20px;

        @include media-breakpoint-down(lg) {
            // reducing whitespace to better accomodate large and smaller screen display
            margin-right: 10px;
        }
        @include media-breakpoint-down(md) {
          padding-top: 15px;
          padding-bottom: 12px;
        }
        .modal & {
          font-size: 1.5rem;
          width: 100%;
          text-align: left;
        }
        &.dropdown-toggle {
          margin-right: 42px;
          @include media-breakpoint-down(lg) {
            // reducing whitespace to better accomodate large and smaller screen display
            margin-right: 10px;
          }
        }
    }
    // the last menu item shouldn't have the right margin
    &:last-child {
      & > button, & > a {
        margin-right: 0;
        &.dropdown-toggle {
          margin-right: 0;
        }
      }
    }
  }
	@include media-breakpoint-up(md){
  	.menu-item:hover, .menu-item:active, .menu-item:focus .menu-item.show, .current-menu-item, .current-menu-ancestor {
    	& > button, & > a:not(.dropdown-item) {
      	border-bottom: solid 4px theme-color( 'primary' );
    	}
  	}
	}
  .dropdown-menu {
    @include media-breakpoint-down(sm) {
      // on mobile, we want the dropdowns to behave more like accordions
      // bootstrap sets the styles via inline, so we must use important
      position: static!important;
      transform: none!important;
			.menu-item {
				a{
					padding-left: 0;
				}
			}
			&.show{
				margin-bottom: 15px;
				border:none;
				li.menu-item{
					padding-left: 0;
				}
			}
    }
    @include media-breakpoint-up(sm) {
				padding:0;
				border:none;
    }
    .menu-item {
      margin: 40px 29px 16px 0;
      @include media-breakpoint-down(md) {
        margin: 20px 15px 0px 0px;
      }
      &:last-of-type {
        margin-bottom: 20px;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        & > a{
          //border-bottom: none;
        }

      }
      .image-overlay {
        position: relative;
        &:hover:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: block;
          background: linear-gradient(to right, rgba(14, 14, 14, 0.5) 5%, rgba(14, 14, 14, 0) 100%);
        }
      }
      .dropdown-item {
        position:relative;
        display:inline-block;
        &:hover {
          background-color: white;
          //border-bottom: none;
          span {
            text-decoration: none;
            border-bottom: solid 4px theme-color( 'primary' );
          }
        }
      }
    }
  }
  .dropdown {
    position: static;
		@include media-breakpoint-down(sm){
			&.show{
				.dropdown-toggle::after{
					transform: rotate(90deg);
				}
			}
		}
    &-toggle::after {
      border: none;
      content: "\f078";
      font-family: 'Font Awesome 5 Pro';
      vertical-align: 0;
      .modal & {
        float: right;
        content: "\f054";
      }
    }
    img {
      display: none;
      margin-bottom: map-get( $spacers, 3 );
    }
    @include media-breakpoint-up(md) {
      &-menu {
        left: 0;
        right: 0;
        margin-top: 0;
        border-color: white;
        border-top-color: theme-color('pale-light');
        justify-content: center;
        &.show {
          display: flex;
        }
        .menu-item {
          flex: 1;
          max-width: 293px;
          color: $headings-color;
        }
      }
      &-item {
        display: flex;
        flex-direction: column;
        font-size: $h4-font-size;
        line-height: $h4-line-height;
        font-weight: $font-weight-bold;
      }
      img {
        display: block;
      }
    }
  }
}

.woocommerce div.product {
  p.price,
  span.price {
    color: $green;
    del {
      opacity: 1;
    }
  }
  .stock {
    color: $green;
  }
  .woocommerce-product-gallery__image{
    > a{
      display: block;
      border: 2px solid transparent;
      &:focus{
        border-color: $input-btn-focus-box-shadow-color;  
      }
    }
  }
}
.woocommerce #respond{
  textarea{
    &:focus{
      box-shadow: $input-btn-focus-box-shadow;
    }
  }
  input#submit{
    &:focus{
      box-shadow: $input-btn-focus-box-shadow;    
    }
  }
} 
.header-widget-area {
  .widget {
		@include media-breakpoint-down(lg) {
			// reducing whitespace to better accomodate large and smaller screen display
			margin-left: 10px;
		}
    margin-left: 42px;
  }
  @include media-breakpoint-down(md) {
    &.secondary-nav-mobile {
      .widget {
        margin-left: 0;
        margin-bottom: 0.5rem;
        a.text-dark {
					padding-top: 15px;
					padding-bottom: 12px;
					padding-left: 5px;
          color: $black !important;
          @include hover-focus-active {
            color: rgba(14,14,14,.7) !important;
						text-decoration: none;
          }
        }
        button {
          &[data-target="#searchModal"] {
            display: none;
          }
        }
      }
    }
  }
	@include media-breakpoint-down(sm){
		&.secondary-nav-mobile{
			margin-top:0;
			margin-bottom:0;
			.widget{
				margin-bottom: calc(.5rem + 5px);
				a.text-dark {
					@include font-size(17px);
				}
			}
		}
	}
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  .text-light & {
    color: theme-color( 'bold-light');
  }
}
h1, .h1 {
  &.qi {
    line-height: 75px;
  }
    font-weight: 700;
    line-height: $h1-line-height;
    @include media-breakpoint-down(md){
      font-size: 34px;
      line-height: 38px;
      &.qi {
        line-height: 40px;
      }
    }

}
h2, .h2 {
    font-weight: 700;
    line-height: $h2-line-height;
		@include media-breakpoint-up(md){
			line-height: $h2-desktop-line-height;
    }
    @include media-breakpoint-down(md){
      font-size: xx-large;
    }
}
h3, .h3 {
    font-weight: 500;
    line-height: $h3-line-height;
}
h4, .h4 {
    font-weight: 700;
    line-height: $h4-line-height;
}
h5, .h5 {
    font-weight: 500;
}

#page-bottom{
	margin-top: 50px;
	.cover-block-widget-text{
		font-size: 1.21429rem;
		margin-top: 0;
		display: block;
		margin-block-start: 1.33em;
		margin-block-end: 1.33em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		a{
			color: $white;
			font-weight: $headings-font-weight;
			line-height: 40px;
			@include media-breakpoint-up(md){
				@include font-size(35px);
				line-height: 51px;
			}
		}
	}
	.icon{
		margin-right: 8px;
		margin-top: -8px;
		@include font-size(24px);
		@include media-breakpoint-up(md){
			@include font-size(40px);
			margin-top: 0;
			margin-right: 0;
			margin-bottom: -16px;
		}
	}
	.cover-block-widget{
		min-height: 128px;
		@include media-breakpoint-up(md){
			flex-direction: column;
			min-height: 288px;
		}
		.cover-block-widget-overlay{
			flex-direction: row;
			position: absolute;
			left: 0;
			right: 0;
			top:0;
			bottom:0;
			background: rgba(39,47,54,.41);
			@include media-breakpoint-up(md){
				flex-direction: column;
			}
		}
	}
}

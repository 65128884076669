#searchModal {
    padding-right: 0 !important;
    background: rgba(0,0,0,0.5);
    .modal-dialog.modal-dialog-centered {
        max-width: 100vw;
        margin: 0 15%;
    }
    .modal-content {
        background-color: inherit;
        border: none;
        .modal-body{
            input#s{
                display: inline-block;
                height: 64px;
                min-width: 0;
                margin: 0;
                border: 0;
                padding: 0;
                background: none;
                color: white;
                font-size: 50px;
                text-align: center;
                border-bottom: 1px solid #ffffff;
                vertical-align: middle;
                white-space: normal;
                &::placeholder {
                    color: white;
                }
            }
            .input-group-append {
                // Apply sr-only rules to submit button in modal
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }
            .form-control:focus {
                box-shadow: none;
            }
            input[type="search"]::-webkit-search-cancel-button {
                -webkit-appearance: none;
                appearance: none;
              }
            .search-clear {
                position: absolute;
                right: 16px;
                top: 26px;
                font-size: 25px;
                font-weight: bold;
                color: red;
                background: none;
                border: none;
                z-index: 1000;
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }
    }
    button.close[data-dismiss="modal"] {
        position: absolute;
        top: -180px;
        right: 16px;
        color: #fff;
    }
}
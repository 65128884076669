// Fix billing field wrapper
.woocommerce-input-wrapper {
	width: 100%;
}

// Woocommerce product gallery slider width fix
figure.woocommerce-product-gallery__wrapper {
	max-width: inherit !important;
}

// Fix coupon code input width
@media(min-width: 768px) {
	#coupon_code.input-text {
		width: 110px !important;
	}
}


// Swatch sizing
a.swatch-anchor {
	height: 100px !important;
	width: 100px !important;
	color: unset;
	text-align: center;
	display: block;
	margin: 0 auto;
	img {
		width: 100%;
	}
}

// Swatch variations table
table.variations {
	width: 100%;
	@media(min-width: 400px){
		width: 90%;
	}
	@include media-breakpoint-up(sm){
		width: 90%;
	}
	@include media-breakpoint-up(md){
		width: 70%;
	}
	@include media-breakpoint-up(lg){
		width: 75%;
	}
	td.label{
		display: none;
	}
	td.value{
		width: 100%;
		display: block;
		margin-right: auto;
		@include media-breakpoint-up(md){
			//width: 75%;
		}
	}
	label{
		display: none;
	}
	a.reset_variations {
		display: none !important;
	}
	div.attribute_finish_picker_label.swatch-label,
	div.attribute_pa_finish_picker_label.swatch-label,
	div.attribute_pa_cooking-ring-options_picker_label.swatch-label{
		display: none;
	}
}

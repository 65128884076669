/**
 * Special card styling
 */
img.contained-card-image { // img necessary for wp styling override
    height: 100%;
    width: auto;
    max-width: none;
}
.card-entry-content {
    padding-top: .75rem;
    padding-bottom: .75rem;
}

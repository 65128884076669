.bytesco-block.category-cards {
  @include media-breakpoint-down(md) {
    margin-bottom: 0px !important;
  }
  .cards {
    & > div {
      &:not(:last-of-type) {
        margin-bottom: $bytesco-block-spacing;
        &.no-spacing-large {
          @include media-breakpoint-up(lg) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .card {
    border-color: rgba($gray-200, 0.5);
    .img {
      min-height: 75px;
      background-position: center center;
      background-size: cover;
    }
    .card-title {
      @include font-size(22px);
      line-height: 29px;
    }
    .card-link {
      .link-text {
        color: $black;
      }
    }
  }
}
.utility-header {
  border-bottom: 1px solid theme-color( 'pale-light' );
  #utility-menu {
    margin: $utility-nav-spacing-y 0;
  }
  .menu-item {
    border-right: 1px solid theme-color('pale-light');
    a {
      @include utility-menu-link;
      @include font-size(12px);
      line-height: 18px;
      padding: 9px 25px;
      display: inline-block;
    }
    select, #gtranslate_selector { // the gtranslate select has its own selector with an id, so need to override it
        @include font-size(12px);
    }
  }
}


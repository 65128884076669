.single-product {
	.single_variation_wrap {
		display: none !important;
	}
}

.product-configuration-options{
	div.container.d-flex .row {
		div.col-lg-6.col-12 {
			@include media-breakpoint-up(md) {
				height: 682px;
				width: 722px;
				&:first-child {
					height: auto;
					width: 643px;
					margin: 55px -54.5px 10px 53.5px;
					z-index: 100;
					background: white;
				}
			}
		}
	}
	.accordion{
		.card{
			background: $white;
			border-left: none;
			border-right: none;
			.card-header {
				background: none;
				border-bottom: none;
				h2, button.btn {
					width: 100%;
				}
				button.btn {
					display: flex;
					justify-content: space-between;
					padding: 21px 0px;
				}
				.h3 {
					display: inline-block;
					vertical-align: middle;
					color: #272F36;
					font-family: Poppins;
					font-size: 17px;
					font-weight: bold;
					letter-spacing: 0;
					line-height: 25px;
				}
				.btn-link {
					text-decoration: none;
				}
				i {
					font-size: 24px;
				}
			}
			select#finish {
				display: none;
			}
		}
	}

	.select-option.swatch-wrapper {
		@include media-breakpoint-up(sm){
			margin-right: 40px;
		}
		margin-right: 32px;
		margin-bottom: 70px;
		float: left;
	}

	.swatch-title-wrapper {
		display: flex;
		color: #5F6058;
		font-family: Poppins;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 24px;
	}

	p.swatch-title {
		margin-right: 40px;
		width: 74px;
		text-align: center;
		word-wrap: normal;
	}

	.find-a-dealer-btn {
		height: 60px;
		width: 171px;
		margin-top: 75px;
		padding-top: 19px;
	}
}

.product-resources {
	padding-top: 30px;
	& > .row {
		&:not(:first-of-type) {
			& > div {
				margin-bottom: $grid-gutter-width/2;
			}
		}
	}
}

@keyframes slideup {
  0% {
    transform: translateY(33px);
  }
  5% {
    transform: translateY(30px);
  }
  12% {
    transform: translateY(28px);
  }
  18% {
    transform: translateY(26px);
  }
  25% {
    transform: translateY(24px);
  }
  30% {
    transform: translateY(22px);
  }
  35% {
    transform: translateY(20px);
  }
  45% {
    transform: translateY(18px);
  }
  50% {
    transform: translateY(16px);
  }
  55% {
    transform: translateY(14px);
  }
  60% {
    transform: translateY(12px);
  }
  65% {
    transform: translateY(10px);
  }
  70% {
    transform: translateY(8px);
  }
  80% {
    transform: translateY(6px);
  }
  90% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0px);
  }
}


@keyframes adminslideup {
  100% {
      transform: translateY(-34px);
  }
  90% {
      transform: translateY(-30px);
  }
  80% {
      transform: translateY(-28px);
  }
  70% {
      transform: translateY(-26px);
  }
  65% {
      transform: translateY(-24px);
  }
  60% {
      transform: translateY(-22px);
  }
  55% {
      transform: translateY(-20px);
  }
  50% {
      transform: translateY(-18px);
  }
  45% {
      transform: translateY(-16px);
  }
  35% {
      transform: translateY(-14px);
  }
  25% {
      transform: translateY(-12px);
  }
  18% {
      transform: translateY(-10px);
  }
  15% {
      transform: translateY(-8px);
  }
  12% {
      transform: translateY(-6px);
  }
  5% {
      transform: translateY(-3px);
  }
  0% {
      transform: translateY(0px);
  }
}